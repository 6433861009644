import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  let options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.get(`${url}/purchases-payments`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/purchases-payments/${id}?include=organization,invoice,allowedLocations,signatures`,
      options
    )
    .then((response) => {
      let purchasesPayment = jsona.deserialize(response.data);
      delete purchasesPayment.links;
      return purchasesPayment;
    });
}

function add(purchasesPayment) {
  const payload = jsona.serialize({
    stuff: purchasesPayment,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/purchases-payments`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(purchasesPayment) {
  const payload = jsona.serialize({
    stuff: purchasesPayment,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/purchases-payments/${purchasesPayment.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/purchases-payments/${id}`, options);
}


function validate(purchasesPayment) {
  const payload = jsona.serialize({
    stuff: purchasesPayment,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/purchases-payments/${purchasesPayment.id}/validate`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}


function cancel(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/purchases-payments/${id}/cancel?include=organization,invoice,allowedLocations`,
      options
    )
    .then((response) => {
      let purchasesPayment = jsona.deserialize(response.data);
      delete purchasesPayment.links;
      return purchasesPayment;
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  validate,
  cancel
};
