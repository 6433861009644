export const VEHICLE_STATUS_DRAFT = "DRAFT";
export const VEHICLE_STATUS_COMPLETED = "COMPLETED";

export const VEHICLE_AUCTION_STATUS_SOON = "SOON";
export const VEHICLE_AUCTION_STATUS_ONGOING = "ONGOING";
export const VEHICLE_AUCTION_STATUS_COMPLETED = "COMPLETED";

export const VEHICLE_STATUS_LIST = [
  VEHICLE_STATUS_DRAFT,
  VEHICLE_STATUS_COMPLETED,
];

export const VEHICLE_CAR_TYPE_CONVERTIBLE = "CONVERTIBLE";
export const VEHICLE_CAR_TYPE_COUPE = "COUPE";
export const VEHICLE_CAR_TYPE_HATCHBACK = "HATCHBACK";
export const VEHICLE_CAR_TYPE_SEDAN = "SEDAN";
export const VEHICLE_CAR_TYPE_SUV = "SUV";
export const VEHICLE_CAR_TYPE_TRUCK = "TRUCK";
export const VEHICLE_CAR_TYPE_VAN = "VAN";
export const VEHICLE_CAR_TYPE_STATION_WAGON = "STATION WAGON";
export const VEHICLE_CAR_TYPE_COMMERCIAL = "COMMERCIAL";
export const VEHICLE_CAR_TYPE_CROSSOVER = "CROSSOVER";
export const VEHICLE_CAR_TYPE_PICKUP_TRUCK = "PICKUP TRUCK";
export const VEHICLE_CAR_TYPE_MINIVAN = "MINIVAN";
export const VEHICLE_CAR_TYPE_ROADSTER = "ROADSTER";
export const VEHICLE_CAR_TYPE_MICROCAR = "MICROCAR";
export const VEHICLE_CAR_TYPE_CHASSIS_CAB = "CHASSIS CAB";
export const VEHICLE_CAR_TYPE_REG_CAB = "REG CAB";
export const VEHICLE_CAR_TYPE_EXT_CAB = "EXT CAB";
export const VEHICLE_CAR_TYPE_CREW_CAB = "CREW CAB";
export const VEHICLE_CAR_TYPE_LUXURY_CAR = "LUXURY CAR";
export const VEHICLE_CAR_TYPE_EV = "EV";
export const VEHICLE_CAR_TYPE_HYBRID_VEHICLE = "HYBRID VEHICLE";
export const VEHICLE_CAR_TYPE_CUV = "CUV";
export const VEHICLE_CAR_TYPE_SUPERCREW = "SUPERCREW";
export const VEHICLE_CAR_TYPE_SUPERCAB = "SUPERCAB";
export const VEHICLE_CAR_TYPE_EXT_WAGON = "EXT WAGON";
export const VEHICLE_CAR_TYPE_UTILITY = "UTILITY";
export const VEHICLE_CAR_TYPE_WAGON = "WAGON";
export const VEHICLE_CAR_TYPE_QUAD_CAB = "QUAD CAB";
export const VEHICLE_CAR_TYPE_KING_CAB = "KING CAB";
export const VEHICLE_CAR_TYPE_DOUBLE_CAB = "DOUBLE CAB";

export const VEHICLE_CAR_TYPES = [
  VEHICLE_CAR_TYPE_CONVERTIBLE,
  VEHICLE_CAR_TYPE_COUPE,
  VEHICLE_CAR_TYPE_HATCHBACK,
  VEHICLE_CAR_TYPE_SEDAN,
  VEHICLE_CAR_TYPE_SUV,
  VEHICLE_CAR_TYPE_TRUCK,
  VEHICLE_CAR_TYPE_VAN,
  VEHICLE_CAR_TYPE_STATION_WAGON,
  VEHICLE_CAR_TYPE_COMMERCIAL,
  VEHICLE_CAR_TYPE_CROSSOVER,
  VEHICLE_CAR_TYPE_PICKUP_TRUCK,
  VEHICLE_CAR_TYPE_MINIVAN,
  VEHICLE_CAR_TYPE_ROADSTER,
  VEHICLE_CAR_TYPE_MICROCAR,
  VEHICLE_CAR_TYPE_CHASSIS_CAB,
  VEHICLE_CAR_TYPE_REG_CAB,
  VEHICLE_CAR_TYPE_EXT_CAB,
  VEHICLE_CAR_TYPE_CREW_CAB,
  VEHICLE_CAR_TYPE_LUXURY_CAR,
  VEHICLE_CAR_TYPE_EV,
  VEHICLE_CAR_TYPE_HYBRID_VEHICLE,
  VEHICLE_CAR_TYPE_CUV,
  VEHICLE_CAR_TYPE_SUPERCREW,
  VEHICLE_CAR_TYPE_SUPERCAB,
  VEHICLE_CAR_TYPE_EXT_WAGON,
  VEHICLE_CAR_TYPE_UTILITY,
  VEHICLE_CAR_TYPE_WAGON,
  VEHICLE_CAR_TYPE_QUAD_CAB,
  VEHICLE_CAR_TYPE_KING_CAB,
  VEHICLE_CAR_TYPE_DOUBLE_CAB,
];

// drivetrans
export const VEHICLE_DRIVETRAIN_LIST = ["4x4", "4WD", "AWD", "FWD", "RWD"];

// colors
export const VEHICLE_COLORS = {
  BLACK: "#000000",
  WHITE: "#FFFFFF",
  GRAY: "#B8B8B8",
  BLUE: "#2B2BB9",
  GREEN: "#008000",
  RED: "#FF0000",
  YELLOW: "#FFFF00",
};

// conditions
export const VEHICLE_CONDITION_BAD = "BAD";
export const VEHICLE_CONDITION_AVERAGE = "AVERAGE";
export const VEHICLE_CONDITION_GOOD = "GOOD";
export const VEHICLE_CONDITION_NEW = "NEW";

export const VEHICLE_CONDITION_LIST = [
  VEHICLE_CONDITION_BAD,
  VEHICLE_CONDITION_AVERAGE,
  VEHICLE_CONDITION_GOOD,
  VEHICLE_CONDITION_NEW,
];

export const VEHICLE_SALE_CONFIRMATION_STATUS_PENDING = 'PENDING';

export const VEHICLE_SALE_CONFIRMATION_STATUS_CONFIRMED = 'CONFIRMED';

export const VEHICLE_SALE_CONFIRMATION_STATUS_CANCELED = 'CANCELED';

export const  VEHICLE_SALE_CONFIRMATION_STATUS = [
  VEHICLE_SALE_CONFIRMATION_STATUS_PENDING,
  VEHICLE_SALE_CONFIRMATION_STATUS_CONFIRMED,
  VEHICLE_SALE_CONFIRMATION_STATUS_CANCELED,
];

export const QUERY_ACTIONS_VALIDATE = "validate";